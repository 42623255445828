<div class="container " [ngClass]="{hasView: !!activeView}">
  <div class="row">
    <div class="col-xl-3 col-lg-4 ">
      <!-- <app-breadcrumb [shadeBack]="'white'" [breadcrumb]="breadCrumbData">
        </app-breadcrumb> -->

      <div class="mt-3 mb-3 py-2 px-3 data-container">

        <ng-container *ngFor="let item of dashboardData;let i = index">
          <a [routerLink]="[item.url]" [queryParams]="{view: true}" routerLinkActive="activeDataIndex"
            [routerLinkActiveOptions]="{ exact: true }" class="row py-2 mx-0 my-2 pointer navLink">
            <div class="col-1 d-flex img-pad-left iconCont">
              <img *ngIf="item.iconLoc == 'server'" [src]="imgUrl + item.icon" />
              <img *ngIf="item.iconLoc == 'assets'" [src]="'/assets/images/' + item.icon" />
            </div>
            <div class="col-11">
              <span>{{item?.dashboardValue}}</span>
            </div>
          </a>
        </ng-container>
        <a href="javascript:void(0)" class="row py-2 mx-0 my-2 pointer navLink" (click)="onLogout()">
          <div class="col-1 d-flex img-pad-left iconCont">
            <img src="/assets/images/dashboard-log-out.svg" />
          </div>
          <div class="col-11">
            <span>Logout</span>
          </div>
        </a>
      </div>

    </div>
    <div class="col-xl-9 col-lg-8 viewsCont">
      <div class="">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>