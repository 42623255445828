<div class="container-fluid p-4">
  <div class="row">
    <div class="col-md-4">
      <div class="stepperControls">
        <div *ngFor="let step of steps; let i = index" [ngClass]="{active: activeStep == (steps.length - i)}"
          class="control" [ngStyle]="{width: 100/steps.length+'%'}">
          <span class="bar"></span>
          <span class="check">
            <span class="material-icons done"> check </span>
            <span class="material-icons edit">edit</span>
          </span>
          <span class="d-block label">STEP {{steps.length - i}}</span>
          <span class="d-block title">{{step}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <p mat-dialog-title class="fs-18 title-text mt-3 mb-1 color-green">
        Change Email Address
      </p>
      <div mat-dialog-content>
        <div class="mt-4 fieldsCont">
          <ng-container *ngIf="activeStep == 1">
            <form class="form-group" (submit)="getOTP()">
              <label>Enter new email address</label>
              <div>
                <input class="no-outline profile-inputs" style="width: calc(100% - 120px);" type="text" name="email" #emailRef="ngModel"  [(ngModel)]="email" required pattern='^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$' />
                <button type="submit" [disabled]="emailRef.invalid" class="btn-sm ms-2 otp-button no-outline color-green font-14">
                  GET OTP
                </button>
              </div>
              <div *ngIf="emailRef.touched" class="text-danger font-12">
                <span *ngIf="emailRef.errors?.['required']">This field is required</span>
                <span *ngIf="emailRef.errors?.['pattern']">Please enter valid email</span>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="activeStep == 2">
            <div class="form-group">
              <label class="d-block mb-2">Please enter OTP sent to {{email}}</label>
              <div class="d-flex align-items-center otpWrapper">
                <div class="me-3">
                  <input *ngFor="let item of otpArray;let i = index" class="otp-container no-outline me-2 py-1 px-2 align-center" type="text" maxlength="1"
                  (change)="getOtpValue($event,i)" />
                </div>
                <div class="flex-shrink-0">
                  <span *ngIf="sec != '00'">00:{{sec}}</span>
                  <button type="button" *ngIf="sec == '00'" class="btn-sm otp-button no-outline color-green font-14"
                      (click)="getOTP()">
                      Resend
                    </button>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label class="d-block">{{'Modal.ChangeEmailPassword.enterPassword'|translate}}</label>
              <input class="no-outline profile-inputs w-100" style="max-width: 250px;" type="password" [(ngModel)]="password" />
            </div> -->
            <div class="form-group">
              <button class="btn py-2 px-4 save-button" (click)="saveChanges()">
                Confirm
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="activeStep == 3">
            <span class="color-green material-icons" style="font-size: 42px;">
              check_circle
            </span>
            <p style="font-weight: 600;"><span class="color-green">Email Address</span> Updated Successfully!</p>
            <button class="btn btn-primary px-4 py-1" (click)="done()">CLOSE</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>