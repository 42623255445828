<svg class="svgIcon" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512"
  height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"
  >
  <g>
    <path d="M298.5 75h-170V53.142C128.5 32.077 145.577 15 166.642 15h93.715c21.065 0 38.142 17.077 38.142 38.142V75z"
      style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" fill="none"
      stroke="currentColor" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
      data-original="currentColor" class=""></path>
    <path d="M124.74 45H41.5v452h344M302.26 45h83.24v282"
      style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" fill="none"
      stroke="currentColor" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
      data-original="currentColor" class=""></path>
    <circle cx="385.5" cy="412" r="85"
      style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" fill="none"
      stroke="currentColor" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
      data-original="currentColor" class=""></circle>
    <path
      d="m357.5 411 19 19 34-34M299.5 191h-56v-56h-60v56h-56v60h56v56h60v-56h56zM313.39 367H106M184 427h-78M301.82 427H244"
      style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" fill="none"
      stroke="currentColor" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
      data-original="currentColor" class=""></path>
  </g>
</svg>