<div class="pt-3 mb-2">
    <span class="material-icons d-lg-none backBtn pointer" routerLink="/my-account">arrow_back</span>
    <span class="heading-text">Health Stats</span>
</div>
<div class="container-div my-3 py-3">
    <div id="calendar">
        <div class="d-flex align-items-center mb-4">
            <div class="dropdown">
                <a class="btn btn-light dropdown-toggle" mat-button [matMenuTriggerFor]="menu">
                    {{tabs[activeTab].label}}
                </a>
                <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let tab of tabs | keyvalue">
                        <button mat-menu-item *ngIf="tab.key != activeTab" class="dropdown-item"
                            href="javascript: void(0);" (click)="onChangeTab(tab.key)">{{tab.value.label}}</button>
                    </ng-container>
                </mat-menu>
            </div>
            <hr class="line">
        </div>
        <div class="d-sm-flex align-items-center justify-content-between dateMain">
            <div>
                <button type="button" class="btn btn-outline-primary py-2 addStats" (click)="openModal()">
                    <i class="material-icons font-18 align-middle">add</i>
                    {{activeTab == 'vitals'? 'ADD VITALS':'ADD SYMPTOM'}}
                </button>
            </div>
            <div class="mt-3 mt-sm-0">
                <form [formGroup]="rangeForm" *ngIf="activeTab == 'vitals'"
                    class="d-flex align-items-center healthStatsRangeForm" (submit)="getVitals()">
                    <div class="d-flex align-items-center healthDate">
                        <div class="text d-none d-sm-inline-block">
                            FROM
                        </div>
                        <div>
                            <input formControlName="startDate" type="date" class="form-control" [max]="maxDate"
                                placeholder="yyyy-mm-dd" />
                        </div>
                    </div>
                    <div class="d-flex align-items-center healthDate me-3">
                        <div class="text mx-2 mx-sm-3">
                            TO
                        </div>
                        <div>
                            <input formControlName="endDate" type="date" class="form-control" [max]="maxDate"
                                placeholder="yyyy-mm-dd" />
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary font-size-24 px-2 py-1"><i
                            class="material-icons align-middle">arrow_forward</i></button>
                </form>
            </div>

        </div>
        <!-- calendar -->
        <div *ngIf="activeTab == 'symptoms'">
            <div class="mb-4 symptomsCont">
                <div class="d-flex justify-content-center align-items-center calendarHead">
                    <i class="material-icons pointer p-2 text-primary font-size-24"
                        (click)="changeSymptomsFilterDate('prev')">chevron_left</i>
                    <span class="px-4">{{sliderDate | date: 'MMM yyyy'}}</span>
                    <i class="material-icons pointer p-2 text-primary font-size-24"
                        (click)="changeSymptomsFilterDate('next')">chevron_right</i>
                </div>
                <!-- (swiper)="onSwiper($event)" (slideChange)="onSlideChange()" -->
                <app-card>
                    <div body>
                        <!-- [breakpoints]="swiperBreakpints" -->
                        <swiper-container class="mySwiper" space-between="5" navigation="true" slides-per-view="auto">
                            <swiper-slide class="slide-item" *ngFor="let data of symptomsList">
                                <span></span>
                                <!-- <app-product class=" product" [product]="productItem"></app-product> -->
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </app-card>
                <!-- <swiper-container class="mySwiper" space-between="5" navigation="true" slides-per-view="auto">
                    <swiper-slide class="slide-item" *ngFor="let data of symptomsList;">
                        <h5>data</h5>
                    </swiper-slide>
                </swiper-container> -->
                <!-- <div class="symptomsSlide pointer" [ngClass]="{active: selectedDay == data}"
                            (click)="onSelectDay(data)">
                            <div class="text-center p-2 day">{{data.label}}</div>
                            <ul class="list-unstyled symptoms">
                                <li *ngFor="let symptom of data.symptoms" class="px-2 py-3 mb-2">
                                    <h6>{{symptom.name}}</h6>
                                    <span class="twoLines">{{symptom.description}}</span>
                                    <span class="text-muted d-block font-10 b-6 mt-1">{{symptom.createdAt | date:
                                        'hh:mm a'}}</span>
                                </li>
                            </ul>
                        </div> -->
                <!-- <div swiper [config]="config" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()">
                    <ng-template swiperSlide *ngFor="let data of symptomsList;">
                        <div class="symptomsSlide pointer" [ngClass]="{active: selectedDay == data}"
                            (click)="onSelectDay(data)">
                            <div class="text-center p-2 day">{{data.label}}</div>
                            <ul class="list-unstyled symptoms">
                                <li *ngFor="let symptom of data.symptoms" class="px-2 py-3 mb-2">
                                    <h6>{{symptom.name}}</h6>
                                    <span class="twoLines">{{symptom.description}}</span>
                                    <span class="text-muted d-block font-10 b-6 mt-1">{{symptom.createdAt | date:
                                        'hh:mm a'}}</span>
                                </li>
                            </ul>
                        </div>
                    </ng-template>

                </div> -->
            </div>
            <!-- <div class="symptomsCont">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th style="width: 150px;">DATE</th>
                            <th>SYMPTOMS</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="selectedDay">
                        <tr *ngFor="let symptom of selectedDay.symptoms">
                            <td>{{symptom.createdAt | date:'hh:mm a'}} <br> {{symptom.createdAt | date:'dd MMM, yyyy'}}
                            </td>
                            <td>
                                <h6 class="mb-1">{{symptom.name}}</h6>
                                <p>{{symptom.description}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="py-4"></td>
                            <td class="py-4"></td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!selectedDay">
                        <tr>
                            <td colspan="2" class="text-center pt-5">
                                <p>No date selected!</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div>
        <!-- <div *ngIf="activeTab == 'vitals'">
            <div>
                <app-vital-chart [data]="vitals"></app-vital-chart>
            </div>
        </div> -->
    </div>
</div>