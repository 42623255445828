import { Routes } from '@angular/router';

import { UserDashboardComponent } from './shared/components/user-dashboard/user-dashboard.component';
import { UserOrdersComponent } from './shared/components/user-dashboard/user-orders/user-orders.component';
import { UserProfileComponent } from './shared/components/user-dashboard/user-profile/user-profile.component';
import { UserHealthStatsComponent } from './shared/components/user-dashboard/user-health-stats/user-health-stats.component';
import { UserAddressComponent } from './shared/components/user-dashboard/user-address/user-address.component';
import { UserAppoinmentsComponent } from './shared/components/user-dashboard/user-appoinments/user-appoinments.component';
import { HealthPackageSubscriptionComponent } from './shared/components/user-dashboard/health-package-subscription/health-package-subscription.component';
import { ConsultationCalendarComponent } from './modules/consultation-calendar/consultation-calendar.component';
import { SelfHealthAssessmentComponent } from './modules/self-health-assessment/self-health-assessment.component';

export const routes: Routes = [
    {
        path: "", pathMatch: "full", loadComponent: () =>
            import('../app/modules/shop/pages/shop-home/shop-home.component').then(
                (comp) => comp.ShopHomeComponent
            )
    },
    {
        path: "cart/:type", loadComponent: () =>
            import('../app/shared/components/cart/cart.component').then(
                (comp) => comp.CartComponent
            )
    },
    // {
    //     path: "product/:product-slug", loadComponent: () =>
    //         import('../app/modules/shop/pages/product-details/product-details.component').then(
    //             (comp) => comp.ProductDetailsComponent
    //         )
    // },
    {
        path: "product/:product-slug", loadComponent: () =>
            import('../app/modules/shop/pages/product-details/product-details-desk/product-details-desk.component').then(
                (comp) => comp.ProductDetailsDeskComponent
            ), data: { mobileHeader: true }
    },

    {
        path: "health-packages", loadComponent: () =>
            import('../app/modules/health-packages/components/health-package-home/health-package-home.component').then(
                (comp) => comp.HealthPackagesHomeComponent
            )
    },
    {
        path: "health-package/:packageSlug", loadComponent: () =>
            import('../app/modules/health-packages/components/health-package-details/health-package-details.component').then(
                (comp) => comp.HealthPackageDetailsComponent
            )
    },
    {
        path: "consult-us", loadComponent: () =>
            import('../app/modules/consult-us/components/consult-us-home/consult-us-home.component').then(
                (comp) => comp.ConsultUsHomeComponent
            )
    },
    {
        path: "consult-us/doctor/:consultantSlug", loadComponent: () =>
            import('../app/modules/consult-us/components/consult-us-details/consult-us-details.component').then(
                (comp) => comp.ConsultUsDetailsComponent
            )
    },
    {
        path: "consult-us/search/:slug", loadComponent: () =>
            import('../app/modules/consult-us/categories/consult-us-categories/consult-us-categories.component').then(
                (comp) => comp.ConsultUsCategoriesComponent
            )
    },
    {
        path: "consult-us/specializations/:slug", loadComponent: () =>
            import('../app/modules/consult-us/categories/consult-us-categories/consult-us-categories.component').then(
                (comp) => comp.ConsultUsCategoriesComponent
            )
    },
    {
        path: "consult-us/health-concerns/:slug", loadComponent: () =>
            import('../app/modules/consult-us/categories/consult-us-categories/consult-us-categories.component').then(
                (comp) => comp.ConsultUsCategoriesComponent
            )
    },
    {
        path: "consult-us/health-concerns/:parentSlug/:slug", loadComponent: () =>
            import('../app/modules/consult-us/categories/consult-us-categories/consult-us-categories.component').then(
                (comp) => comp.ConsultUsCategoriesComponent
            )
    },
    {
        path: "consult-us/:slug", loadComponent: () =>
            import('../app/modules/consult-us/categories/consult-us-categories/consult-us-categories.component').then(
                (comp) => comp.ConsultUsCategoriesComponent
            )
    },
    {
        path: "lifestyle-tips", loadComponent: () =>
            import('../app/modules/lifestyle-tips/components/lifestyle-tips-home/lifestyle-tips-home.component').then(
                (comp) => comp.LifeStyleTipsHomeComponent
            )
    },
    {
        path: "lifestyle-tips/:titleName", loadComponent: () =>
            import('../app/modules/lifestyle-tips/components/lifestyle-tips-details/lifestyle-tips-details.component').then(
                (comp) => comp.LifestyleTipsDetailsComponent
            )
    },
    {
        path: "lifestyle-tips/category/:slug", loadComponent: () =>
            import('../app/modules/lifestyle-tips/categories/lifestyle-categories/lifestyle-categories.component').then(
                (comp) => comp.LifestyleCategoriesComponent
            )
    },
    {
        path: "lifestyle-tips/search/:query", loadComponent: () =>
            import('../app/modules/lifestyle-tips/categories/lifestyle-categories/lifestyle-categories.component').then(
                (comp) => comp.LifestyleCategoriesComponent
            )
    },
    {
        path: "search/global/:query", loadComponent: () =>
            import('./modules/shop/pages/global-search/global-search.component').then(
                (comp) => comp.GlobalSearchComponent
            )
    },
    {
        path: "search/:slug", loadComponent: () =>
            import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
                (comp) => comp.ProductsListingComponent
            )
    },
    {
        path: "brand/:slug", loadComponent: () =>
            import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
                (comp) => comp.ProductsListingComponent
            )
    },
    {
        path: 'health-concerns/:category/:slug', loadComponent: () => import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
            (comp) => comp.ProductsListingComponent
        )
    },
    {
        path: 'health-concerns/:slug', loadComponent: () => import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
            (comp) => comp.ProductsListingComponent
        )
    },
    {
        path: "category/:slug", loadComponent: () =>
            import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
                (comp) => comp.ProductsListingComponent
            )
    },
    {
        path: "category/:slug/:slug", loadComponent: () =>
            import('./modules/categories/shopNow-category-details/products-listing/products-listing.component').then(
                (comp) => comp.ProductsListingComponent
            )
    },
    {
        path: "payment", loadComponent: () =>
            import('../app/shared/components/payment/payment.component').then(
                (comp) => comp.PaymentComponent
            )
    },
    {
        path: "category/pooja/:id", loadComponent: () =>
            import('./modules/categories/shopNow-category-details/brand-category/brand-category-detail.component').then(
                (comp) => comp.CategoryDetailComponent
            )
    },
    {
        path: "faq", loadComponent: () =>
            import('./shared/components/faq/faq.component').then(
                (comp) => comp.FaqComponent
            )
    },
    {
        path: "policies", loadComponent: () =>
            import('./shared/components/policies/policies.component').then(
                (comp) => comp.PoliciesComponent
            )
    },
    {
        path: "terms", loadComponent: () =>
            import('./shared/components/terms/terms.component').then(
                (comp) => comp.TermsComponent
            )
    },
    {
        path: "about-us", loadComponent: () =>
            import('./shared/components/about-us/about-us.component').then(
                (comp) => comp.AboutUsComponent
            )
    }, {
        path: "cart/checkout/thank-you", loadComponent: () =>
            import('./shared/components/thank-you/thank-you.component').then(
                (comp) => comp.ThankYouComponent
            )
    },
    {
        path: "pay-status", loadComponent: () =>
            import('./shared/components/pay-status/pay-status.component').then(
                (comp) => comp.PayStatusComponent
            )
    },
    {
        path: 'my-account',
        component: UserDashboardComponent,
        children: [
            { path: '', redirectTo: 'profile', pathMatch: 'full' },
            { path: 'profile', component: UserProfileComponent },
            { path: 'orders', component: UserOrdersComponent },
            { path: 'health-stats', component: UserHealthStatsComponent },
            { path: 'manage-address', component: UserAddressComponent },
            { path: 'appointments', component: UserAppoinmentsComponent },
            { path: 'my-subscriptions', component: HealthPackageSubscriptionComponent },
            { path: 'calender', component: ConsultationCalendarComponent },
        ]
    },
    {
        path: 'my-account/appointments/:id', loadComponent: () =>
            import('./shared/components/user-dashboard/appointment-detail/appointment-detail.component').then(
                (comp) => comp.AppointmentDetailComponent
            )
    },
    {
        path: 'self-health-assessment', loadComponent: () =>
            import('./modules/self-health-assessment/self-health-assessment.component').then((comp) =>
                comp.SelfHealthAssessmentComponent
            )
    },
    {
        path: 'self-health-assessment/:id', loadComponent: () =>
            import('./modules/self-health-assessment/assessment-form/assessment-form.component').then((comp) =>
                comp.AssessmentFormComponent
            )
    },
    {
        path: 'become-consultant', loadComponent: () =>
            import('./components/add-consultant/add-consultant.component').then((comp) =>
                comp.AddConsultantComponent
            )
    },
    {
        path: 'order-tracker', loadComponent: () =>
            import('./modules/shop/pages/order-tracker/order-tracker.component').then((comp) =>
                comp.OrderTrackerComponent
            )
    },
    {
        path: '**', loadComponent: () =>
            import('./shared/components/error-404/error-404.component').then((comp) =>
                comp.Error404Component
            )
    },

];